<template>
  <div>
    <video ref="videoElement" controls width="976px">
      <source :src="videoURL" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
// import adminInstance from "@/lib/adminInstance";

export default {
  props: {
    videoName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const videoElement = ref(null);
    const baseURL = process.env.NODE_ENV === "production" ? "https://app.mnb.mn/api/mnb/admin" : "http://localhost:3002/api/mnb/admin";
    const videoURL = ref(`${baseURL}${props.videoName}`);

    watch(
      () => props.videoName,
      (newVideoName) => {
        videoURL.value = `${baseURL}/${newVideoName}`;
        if (videoElement.value) {
          videoElement.value.load();
        }
      }
    );

    onMounted(() => {
      if (videoElement.value) {
        videoElement.value.addEventListener("error", (event) => {
          console.error("Video playback failed", event);
        });
      }
    });

    return {
      videoElement,
      videoURL,
    };
  },
};
</script>
