<template>
  <admin-container>
    <div class="text-red-500 uppercase">Testing tv archive</div>

    <div class="grid grid-cols-1 md:grid-cols-9 gap-4 mb-4">
      <div class="col-span-1 md:col-span-2">
        <label for="channelName" class="block mb-2 text-sm font-medium text-gray-900">Сувгийн нэр</label>
        <select
          id="channelName"
          name="channelName"
          v-model="filterValues.channelName"
          :value="filterValues.channelName"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option selected disabled>Төрөл сонгох</option>
          <option value="1">MNB Үндэсний телевиз</option>
          <option value="2">MNB Монголын мэдээ</option>
          <option value="3">MNB Спорт</option>
          <option value="4">MNB World</option>
          <option value="5">MNB Гэр бүл</option>
        </select>
      </div>
      <div class="col-span-1 md:col-span-2">
        <label for="archiveStatus" class="block mb-2 text-sm font-medium text-gray-900">Архивлагдсан эсэх</label>
        <select
          id="archiveStatus"
          name="archiveStatus"
          v-model="filterValues.archiveStatus"
          :value="filterValues.archiveStatus"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        >
          <option selected disabled>Төрөл сонгох</option>
          <option value="true">Тийм</option>
          <option value="false">Үгүй</option>
        </select>
      </div>
      <div class="col-span-1 md:col-span-2">
        <label for="date" class="block mb-2 text-sm font-medium text-gray-900">Огноо</label>
        <input
          type="date"
          id="date"
          name="date"
          v-model="filterValues.date"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
      </div>

      <div class="col-span-1 md:col-span-2 relative flex flex-col justify-center focus-within:text-blue-500">
        <label for="search" class="block mb-2 text-sm font-medium text-gray-900">Хайх</label>
        <input
          type="text"
          id="search"
          name="search"
          placeholder="Хөтөлбөрийн нэр"
          v-model="filterValues.search"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        />
        <button type="button" class="absolute right-0 top-[40px] mr-2.5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" @click="fetchTvArchiveList">
          <svg
            class="w-5 h-5 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx="10" cy="10" r="7"></circle>
            <line x1="21" y1="21" x2="15" y2="15"></line>
          </svg>
        </button>
      </div>

      <div class="col-span-1 md:col-span-1 flex items-end">
        <button
          type="button"
          @click="clearFilters"
          class="w-10 h-10 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center"
        >
          <font-awesome-icon :icon="['fas', 'times']" />
        </button>
      </div>
    </div>

    <div class="overflow-x-hidden" v-if="!isLoading && tvArchiveList.length > 0">
      <hot-table :settings="hotSettings" :data="tvArchiveList" ref="hotRef" />
    </div>
    <div class="mt-4" v-if="!isLoading && tvArchiveList.length === 0">
      <p class="text-center text-gray-500">Хөтөлбөр олдсонгүй</p>
    </div>

    <div
      class="fixed inset-0 bg-black bg-opacity-50 z-[10000]"
      v-if="isVideoModalOpen || isDeleteModalOpen"
      @click="
        isVideoModalOpen = false;
        isDeleteModalOpen = false;
      "
    ></div>
    <div
      class="absolute top-[30%] l-4 r-4 md:fixed md:top-1/2 md:left-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 overflow-hidden transition-all duration-300 ease-in-out flex flex-col items-center justify-center z-[9999]"
      v-if="isVideoModalOpen"
    >
      <video-player :videoName="`/tv-archive/${videoName}`" />
    </div>
    <delete-modal v-if="isDeleteModalOpen" @close="isDeleteModalOpen = false" @delete="deleteTvArchive" :title="seletectedRowData[2]" message="Та устгахдаа итгэлтэй байна уу?" />
  </admin-container>
</template>

<script>
import { ref, onMounted, watch, reactive, computed } from "vue";
import { useStore } from "vuex";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";

import AdminContainer from "../admin/AdminContainer.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import DeleteModal from "@/components/ui/DeleteModal.vue";

registerAllModules();
export default {
  name: "VideoPage",
  components: {
    VideoPlayer,
    AdminContainer,
    DeleteModal,
    HotTable,
  },
  setup() {
    const store = useStore();
    const hotRef = ref(null);
    const isLoading = computed(() => store.state.admin.isLoading);
    const tvArchiveList = computed(() => store.state.admin.tvArchiveList);

    const videoName = ref("");
    const seletectedRowData = ref(null);
    const isDeleteModalOpen = ref(false);
    const isVideoModalOpen = ref(false);
    const filterValues = reactive({
      channelName: "",
      archiveStatus: "",
      date: "",
      search: "",
    });

    onMounted(() => {
      fetchTvArchiveList();
    });

    watch(
      () => [filterValues.channelName, filterValues.archiveStatus, filterValues.date],
      () => {
        fetchTvArchiveList();
      },
      { deep: true }
    );

    const fetchTvArchiveList = async () => {
      store.dispatch("admin/fetchTvArchiveList", filterValues);
    };

    /*
      action renderer for handsontable
    */
    const actionRenderer = (instance, td, row) => {
      td.classList.add("htMiddle");
      const watchButtonId = `edit-button-${row}`;
      const deleteButtonId = `confirm-button-${row}`;

      td.innerHTML = `<div class="flex justify-center items-center gap-2 py-1">
    <button id="${watchButtonId}" class="w-28 bg-blue-500 text-white rounded-md text-center p-1 hover:bg-blue-600 transition ease-in-out duration-150">Үзэх</button>
    <button id="${deleteButtonId}" class="w-28 bg-red-500 text-white rounded-md text-center p-1 hover:bg-red-600 transition ease-in-out duration-150">Устгах</button>
  </div>`;

      const editButton = document.getElementById(watchButtonId);
      const deleteButtonElement = document.getElementById(deleteButtonId);
      const fileName = instance.getDataAtRowProp(row, "file_name");

      if (editButton) {
        editButton.removeEventListener("click", watchClickHandler.bind(null, fileName));
        editButton.addEventListener("click", watchClickHandler.bind(null, fileName));
      }
      if (deleteButtonElement) {
        deleteButtonElement.removeEventListener("click", deleteClickHandler);
        deleteButtonElement.addEventListener("click", deleteClickHandler);
      }
    };

    function watchClickHandler(fileName) {
      isVideoModalOpen.value = true;
      videoName.value = fileName;
    }

    function deleteClickHandler() {
      isDeleteModalOpen.value = true;
      const hotInstance = hotRef.value.hotInstance;
      const selectedRow = hotInstance.getSelectedLast()[0];
      seletectedRowData.value = hotInstance.getDataAtRow(selectedRow);
    }

    async function deleteTvArchive() {
      try {
        await store.dispatch("admin/deleteTvArchive", seletectedRowData.value[0]);
        await fetchTvArchiveList();
      } catch (err) {
        console.error(err);
      } finally {
        isDeleteModalOpen.value = false;
      }
    }

    const hotSettings = {
      data: tvArchiveList.value,
      colHeaders: ["", "Сувгийн нэр", "Хөтөлбөрийн нэр", "Огноо", "Эхлсэн цаг", "Дууссан цаг", "Файлын нэр", "Архивлагдсан эсэх", "Өөрчлөлт орсон огноо", "Үйлдэл"],
      colWidths: [30, 200, 250, 150, 150, 150, 250, 150, 200, 150],
      columns: [
        // make id hidden
        { data: "id" },
        { data: "channel_name" },
        { data: "program_name" },
        { data: "date" },
        { data: "start_time" },
        { data: "end_time" },
        { data: "file_name" },
        {
          data: "is_archived",
          renderer: function (instance, td, row, col, prop, value) {
            if (value === true) {
              td.style.color = "green";
              td.style.fontWeight = "bold";
              td.style.textAlign = "center";
              td.innerHTML = "Тийм";
            } else {
              td.style.color = "red";
              td.style.fontWeight = "bold";
              td.style.textAlign = "center";
              td.innerHTML = "Үгүй";
            }
          },
        },
        { data: "updated_at" },
        {
          data: "action",
          readOnly: true,
          renderer: actionRenderer,
        },
      ],
      hiddenColumns: {
        columns: [0],
        indicators: false,
      },
      // stretchH: "all",
      fixedColumnsLeft: window.innerWidth < 768 ? 0 : 2,
      width: "100%",
      height: "576px",
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    /*
        Clear filters
    */
    const clearFilters = () => {
      filterValues.channelName = "";
      filterValues.archiveStatus = "";
      filterValues.date = "";
      filterValues.search = "";
      fetchTvArchiveList();
    };

    return {
      isDeleteModalOpen,
      isVideoModalOpen,
      isLoading,
      filterValues,
      videoName,
      tvArchiveList,
      hotRef,
      hotSettings,
      seletectedRowData,
      clearFilters,
      fetchTvArchiveList,
      deleteTvArchive,
    };
  },
};
</script>

<style></style>
