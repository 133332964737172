<template>
  <div class="flex justify-center items-center h-screen">
    <div id="googleSignIn"></div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { authInstance } from "../../lib/adminInstance";
export default {
  name: "LoginPage",
  setup() {
    onMounted(() => {
      initializeGoogleSignIn();
    });

    const initializeGoogleSignIn = () => {
      if (window.google && document.getElementById("googleSignIn")) {
        window.google.accounts.id.initialize({
          client_id: "677235543627-k0kuc9d2e9hhb7h6gkt61i6tug9knj5u.apps.googleusercontent.com",
          callback: handleGoogleSignIn,
        });
        window.google.accounts.id.disableAutoSelect();

        window.google.accounts.id.renderButton(document.getElementById("googleSignIn"), {
          type: "standard",
          size: "large",
          shape: "rectangular",
          logo_alignment: "center",
        });
      }
    };

    // handle google sign in and sign up response from google api callback function in initializeGoogleSignIn function above
    const handleGoogleSignIn = async (response) => {
      try {
        const res = await authInstance.post("/login", {
          idToken: response.credential,
        });

        if (res.status === 200) {
          window.location.href = "/admin/dashboard";
        }
      } catch (error) {
        console.error(error);
        if (error.response.data.statusMessage === "user_not_found") {
          alert(error.response.data.message);
          return;
        }
        return error.response;
      }
    };

    return {
      initializeGoogleSignIn,
    };
  },
};
</script>
