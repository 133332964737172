<template>
  <admin-container>
    <div class="px-4 py-4">
      <h3 class="flex items-center font-bold text-gray-700 mb-4">
        <span>MNB аппликейшн таталт</span>
      </h3>
      <div class="flex gap-4">
        <div class="flex items-center gap-4 bg-white p-6 rounded-lg shadow-lg border border-gray-200">
          <font-awesome-icon :icon="['fab', 'google-play']" class="w-6 h-6 text-green-500" />
          <div>
            <h3 class="text-lg font-semibold text-gray-900">Play Store</h3>
            <div class="text-sm text-gray-600">5000+ таталт</div>
          </div>
        </div>
        <div class="flex items-center gap-4 bg-white p-6 rounded-lg shadow-lg border border-gray-200">
          <font-awesome-icon :icon="['fab', 'apple']" class="w-6 h-6 text-black" />
          <div>
            <h3 class="text-lg font-semibold text-gray-900">App Store</h3>
            <div class="text-sm text-gray-600">5000+ таталт</div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-4">
      <h3 class="mb-4">Системийн мэдээлэл <span class="text-red-500">(10 минут тутамд шинэчлэгдэнэ)</span></h3>

      <table class="min-w-full table-auto">
        <thead class="bg-gray-200">
          <tr>
            <th class="px-4 py-2 text-left text-gray-600">Нэршил</th>
            <th class="px-4 py-2 text-left text-gray-600">Утга</th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white border-b">
            <td class="px-4 py-2 text-gray-700">CPU Usage</td>
            <td class="px-4 py-2 text-gray-800">{{ systemStat?.cpuUsage }}</td>
          </tr>
          <tr class="bg-gray-100 border-b">
            <td class="px-4 py-2 text-gray-700">Disk Usage Percentage</td>
            <td class="px-4 py-2 text-gray-800">{{ systemStat?.diskUsagePercentage }}</td>
          </tr>
          <tr class="bg-white border-b">
            <td class="px-4 py-2 text-gray-700">Free Disk Space</td>
            <td class="px-4 py-2 text-gray-800">{{ systemStat?.freeDiskSpace }}</td>
          </tr>
          <tr class="bg-gray-100 border-b">
            <td class="px-4 py-2 text-gray-700">Total Disk Space</td>
            <td class="px-4 py-2 text-gray-800">{{ systemStat?.totalDiskSpace }}</td>
          </tr>
          <tr class="bg-white border-b">
            <td class="px-4 py-2 text-gray-700">Free Memory</td>
            <td class="px-4 py-2 text-gray-800">{{ systemStat?.freeMemory }}</td>
          </tr>
          <tr class="bg-gray-100 border-b">
            <td class="px-4 py-2 text-gray-700">Total Memory</td>
            <td class="px-4 py-2 text-gray-800">{{ systemStat?.totalMemory }}</td>
          </tr>
          <tr class="bg-white border-b">
            <td class="px-4 py-2 text-gray-700">Used Disk Space</td>
            <td class="px-4 py-2 text-gray-800">{{ systemStat?.usedDiskSpace }}</td>
          </tr>
          <tr class="bg-gray-100 border-b">
            <td class="px-4 py-2 text-gray-700">Used Memory Percentage</td>
            <td class="px-4 py-2 text-gray-800">{{ systemStat?.usedMemoryPercentage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </admin-container>
</template>

<script>
import { onMounted, ref, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import AdminContainer from "./AdminContainer.vue";
// import Chart from "chart.js/auto";
import { adminInstance } from "../../lib/adminInstance";
export default {
  components: {
    AdminContainer,
  },
  setup() {
    const route = useRoute();
    const path = ref(route.path);
    // const isLoading = ref(false);
    const isSidebarOpen = ref(false);
    const systemStat = ref(null);

    // eslint-disable-next-line
    let intervalId = null;

    const toggleSidebar = () => {
      isSidebarOpen.value = !isSidebarOpen.value;
    };

    onMounted(async () => {
      // initChart();
      await fetchStats();

      intervalId = setInterval(async () => {
        await fetchStats();
      }, 600000);
    });

    onUnmounted(() => {
      clearInterval(intervalId);
    });

    // const initChart = async () => {
    //   const downloadsCtx = document.getElementById("downloadsChart").getContext("2d");
    //   const viewersCtx = document.getElementById("viewersChart").getContext("2d");

    //   new Chart(downloadsCtx, {
    //     type: "line",
    //     data: {
    //       labels: ["07-15", "07-16", "07-17", "07-18", "07-19", "07-20", "07-21"],
    //       datasets: [
    //         {
    //           label: "Android таталт",
    //           data: [120, 130, 200, 150, 210, 350, 400],
    //           borderColor: "rgba(75, 192, 192, 1)",
    //           backgroundColor: "rgba(75, 192, 192, 0.2)",
    //           fill: true,
    //         },
    //         {
    //           label: "IOS таталт",
    //           data: [100, 140, 180, 170, 200, 300, 380],
    //           borderColor: "rgba(255, 99, 132, 1)",
    //           backgroundColor: "rgba(255, 99, 132, 0.2)",
    //           fill: true,
    //         },
    //       ],
    //     },
    //     options: {
    //       responsive: true,
    //       plugins: {
    //         legend: {
    //           display: true,
    //         },
    //       },
    //     },
    //   });

    //   new Chart(viewersCtx, {
    //     type: "bar",
    //     data: {
    //       labels: ["07-15", "07-16", "07-17", "07-18", "07-19", "07-20", "07-21"],
    //       datasets: [
    //         {
    //           label: "Шууд ефир үзэлт",
    //           data: [60, 80, 110, 95, 130, 160, 190],
    //           borderColor: "rgba(153, 102, 255, 1)",
    //           backgroundColor: "rgba(153, 102, 255, 0.5)",
    //         },
    //       ],
    //     },
    //     options: {
    //       responsive: true,
    //       plugins: {
    //         legend: {
    //           display: true,
    //         },
    //       },
    //     },
    //   });
    // };

    const fetchStats = async () => {
      try {
        const res = await adminInstance.get("/stats");
        systemStat.value = res.data;
      } catch (e) {
        console.error(e);
      }
    };

    return {
      path,
      isSidebarOpen,
      systemStat,
      toggleSidebar,
    };
  },
};
</script>

<style>
.p-8 flex flex-col gap-20 {
  padding: 20px;
}
.chart {
  flex: 1;
  max-width: 768px;
}
</style>
