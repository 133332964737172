<template>
  <div class="max-w-md flex flex-col gap-4 mb-4">
    <div class="col-span-1">
      <label for="seriesTitle" class="block mb-2 text-sm font-medium text-gray-900">Цувралийн нэр</label>
      <input
        type="text"
        id="seriesTitle"
        name="seriesTitle"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн нэр"
        required
        :value="seriesData.seriesTitle"
        @change="handleSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="seriesCategory" class="block mb-2 text-sm font-medium text-gray-900">Цувралийн төрөл</label>
      <select
        id="seriesCategory"
        name="seriesCategory"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн нэр"
        required
        :value="seriesData.seriesCategory"
        @change="handleSeriesInputChanges"
      >
        <option disabled selected value>-- Сонгох --</option>
        <option v-for="category in categories" :key="category.category_id" :value="category.category_id">{{ category.name }}</option>
      </select>
    </div>
    <div class="col-span-1">
      <label for="seriesThumbnail" class="block mb-2 text-sm font-medium text-gray-900">Цувралийн thumbnail зураг</label>
      <input
        type="file"
        name="seriesThumbnail"
        ref="thumbnailInputRef"
        id="seriesThumbnail"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн үнэ"
        required
        @change="handleSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="videoFile" class="block mb-2 text-sm font-medium text-gray-900"> Цувралийн файл </label>
      <input
        type="file"
        id="videoFile"
        name="videoFile"
        ref="fileInputRef"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн файл"
        required
        @change="handleSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="seriesPrice" class="block mb-2 text-sm font-medium text-gray-900">Цувралийн үнэ</label>
      <input
        type="number"
        id="seriesPrice"
        name="seriesPrice"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн үнэ"
        required
        :value="seriesData.seriesPrice"
        @change="handleSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="seriesDescription" class="block mb-2 text-sm font-medium text-gray-900">Цувралийн бичлэгэн тайлбар</label>
      <textarea
        name="seriesDescription"
        id="seriesDescription"
        cols="30"
        rows="10"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн тайлбар"
        required
        :value="seriesData.seriesDescription"
        @change="handleSeriesInputChanges"
      ></textarea>
    </div>
    <div class="col-span-1">
      <label for="episodeTitle" class="block mb-2 text-sm font-medium text-gray-900">Ангийн нэр</label>
      <input
        type="text"
        id="episodeTitle"
        name="episodeTitle"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн нэр"
        required
        :value="seriesData.episodeTitle"
        @change="handleSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="episodeNumber" class="block mb-2 text-sm font-medium text-gray-900">Ангийн дугаар</label>
      <input
        type="number"
        id="episodeNumber"
        name="episodeNumber"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн нэр"
        required
        :value="seriesData.episodeNumber"
        @change="handleSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="episodeDescription" class="block mb-2 text-sm font-medium text-gray-900">Ангийн тайлбар</label>
      <textarea
        name="episodeDescription"
        id="episodeDescription"
        cols="30"
        rows="10"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн тайлбар"
        required
        :value="seriesData.episodeDescription"
        @change="handleSeriesInputChanges"
      ></textarea>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
export default {
  name: "SeriesUploadForm",

  setup() {
    const store = useStore();
    const seriesData = computed(() => store.state.admin.seriesData);
    const categories = computed(() => store.state.admin.categories);
    const thumbnailInputRef = ref(null);
    const fileInputRef = ref(null);

    const handleSeriesInputChanges = (event) => {
      if (event.target.name === "seriesThumbnail" || event.target.name === "videoFile") {
        store.commit("admin/setSeriesData", { key: event.target.name, value: event.target.files[0] });
        return;
      }
      store.commit("admin/setSeriesData", { key: event.target.name, value: event.target.value });
    };

    watch([() => seriesData.value.seriesThumbnail, () => seriesData.value.videoFile], (newValues, oldValues) => {
      const [newThumbnail, newVideo] = newValues;
      const [oldThumbnail, oldVideo] = oldValues;

      if (!newThumbnail && oldThumbnail) {
        thumbnailInputRef.value.value = "";
      }
      if (!newVideo && oldVideo) {
        fileInputRef.value.value = "";
      }
    });

    return { seriesData, categories, thumbnailInputRef, fileInputRef, handleSeriesInputChanges };
  },
};
</script>
