<template>
  <div class="grid grid-cols-1 md:grid-cols-9 gap-4 mb-4">
    <!-- reset filter button -->

    <div class="col-span-1 md:col-span-2">
      <label for="category" class="block mb-2 text-sm font-medium text-gray-900">Төрөл</label>
      <select
        id="category"
        name="category"
        v-model="filterValues.category"
        :value="filterValues.category"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      >
        <option selected disabled>Төрөл сонгох</option>
        <option value="1">Алтан сан хөмрөг</option>
        <option value="2">Монгол кино</option>
      </select>
    </div>
    <div class="col-span-1 md:col-span-2">
      <label for="date" class="block mb-2 text-sm font-medium text-gray-900">Огноо</label>
      <input
        type="date"
        id="date"
        name="date"
        v-model="filterValues.date"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
    </div>

    <div class="col-span-1 md:col-span-3 relative flex flex-col justify-center focus-within:text-blue-500">
      <label for="search" class="block mb-2 text-sm font-medium text-gray-900">Хайх</label>
      <input
        type="text"
        id="search"
        name="search"
        placeholder="Бичлэгийн нэр"
        v-model="filterValues.search"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
      <button type="button" class="absolute right-0 top-[40px] mr-2.5 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" @click="fetchVodList">
        <svg
          class="w-5 h-5 text-gray-400 hover:text-gray-600 transition ease-in-out duration-150"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx="10" cy="10" r="7"></circle>
          <line x1="21" y1="21" x2="15" y2="15"></line>
        </svg>
      </button>
    </div>

    <div class="col-span-1 md:col-span-1 flex items-end">
      <button
        type="button"
        @click="clearFilters"
        class="w-10 h-10 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center justify-center"
      >
        <font-awesome-icon :icon="['fas', 'times']" />
      </button>
    </div>
  </div>
  <div class="mt-4" v-if="!isLoading && vodList.length > 0">
    <hot-table :settings="hotSettings" :data="vodList" ref="hotRef" />
  </div>
  <div class="mt-4" v-if="!isLoading && vodList.length === 0">
    <p class="text-center text-gray-500">Бичлэг олдсонгүй</p>
  </div>
  <div class="fixed inset-0 bg-black bg-opacity-50 z-[9998]" v-if="isVideoModalOpen" @click="isVideoModalOpen = false"></div>
  <div
    class="absolute top-[30%] l-4 r-4 md:fixed md:top-1/2 md:left-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 overflow-hidden transition-all duration-300 ease-in-out flex flex-col items-center justify-center z-[9999]"
    v-if="isVideoModalOpen"
  >
    <video-player :videoName="`/vod/${videoName}`" />
  </div>
</template>

<script>
import { ref, onMounted, reactive, watch, computed } from "vue";
import { useStore } from "vuex";

import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";

import VideoPlayer from "@/components/VideoPlayer.vue";
import { adminInstance } from "@/lib/adminInstance";
registerAllModules();
export default {
  name: "AdminVodList",
  components: {
    VideoPlayer,

    HotTable,
  },
  setup() {
    const store = useStore();
    const isLoading = computed(() => store.state.admin.isLoading);
    const vodList = computed(() => store.state.admin.vodList);

    const hotRef = ref(null);
    const isVideoModalOpen = ref(false);
    const videoName = ref("");
    const filterValues = reactive({
      category: "",
      status: "",
      date: "",
      search: "",
    });

    const fetchVodList = async () => {
      if (vodList.value.length > 0) {
        return;
      }
      await store.dispatch("admin/fetchVodList", filterValues);
    };

    onMounted(() => {
      fetchVodList();
    });

    watch(
      () => [filterValues.category, filterValues.status, filterValues.date],
      () => {
        fetchVodList();
      },
      { deep: true }
    );

    const actionRenderer = (instance, td, row) => {
      td.classList.add("htMiddle");
      const watchButtonId = `edit-button-${row}`;
      const deleteButtonId = `confirm-button-${row}`;

      td.innerHTML = `<div class="flex justify-center items-center gap-2 py-1">
      <button id="${watchButtonId}" class="w-28 bg-blue-500 text-white rounded-md text-center p-1 hover:bg-blue-600 transition ease-in-out duration-150">Үзэх</button>
      <button id="${deleteButtonId}" class="w-28 bg-red-500 text-white rounded-md text-center p-1 hover:bg-red-600 transition ease-in-out duration-150">Устгах</button>
    </div>`;

      const editButton = document.getElementById(watchButtonId);
      const deleteButtonElement = document.getElementById(deleteButtonId);
      const fileName = instance.getDataAtRowProp(row, "filename");

      if (editButton) {
        editButton.removeEventListener("click", watchClickHandler.bind(null, fileName));
        editButton.addEventListener("click", watchClickHandler.bind(null, fileName));
      }
      if (deleteButtonElement) {
        deleteButtonElement.removeEventListener("click", deleteClickHandler);
        deleteButtonElement.addEventListener("click", deleteClickHandler);
      }
    };

    const watchClickHandler = (fileName) => {
      isVideoModalOpen.value = true;
      videoName.value = fileName;
    };

    const deleteClickHandler = async () => {
      try {
        const hotInstance = hotRef.value.hotInstance;
        const selectedRow = hotInstance.getSelectedLast()[0];
        const selectedId = hotInstance.getDataAtRowProp(selectedRow, "vod_id");
        const response = await adminInstance.delete(`/vod/${selectedId}`);
        if (response.status === 200) {
          hotInstance.alter("remove_row", selectedRow);
        }
        console.log("Delete");
      } catch (error) {
        console.error(error);
      }
    };

    const hotSettings = {
      data: vodList.value,
      colWidths: [null, 200, 200, 200, 100, 200, 200, 200, 200, 200, 200, 200, 200, 200],
      colHeaders: [
        "ID",
        "Бичлэгийн нэр",
        "Бичлэгийн төрөл",
        "Тайлбар",
        "Үнэ (₮)",
        "Оруулсан файлийн нэр",
        "Хадгалагдсан файлын нэр",
        "Бичлэгийн урт (мин)",
        "Бичлэгийн файл төрөл",
        "Бичлэгийн дүрслэл",
        "Бичлэгийн хэмжээ (MB)",
        "Бичлэг оруулсан огноо",
        "Шинэчлэгдсэн огноо",
        "Үйлдэл",
      ],
      columns: [
        { data: "vod_id" },
        { data: "title", readOnly: true },
        { data: "name", readOnly: true },
        { data: "description", readOnly: true },
        { data: "price", readOnly: true },
        { data: "original_filename", readOnly: true },
        { data: "filename", readOnly: true },
        { data: "duration", readOnly: true },
        { data: "file_type", readOnly: true },
        { data: "resolution", readOnly: true },
        { data: "file_size", readOnly: true },
        { data: "created_at", readOnly: true },
        { data: "updated_at", readOnly: true },
        {
          renderer: actionRenderer,
          readOnly: true,
        },
      ],
      hiddenColumns: {
        columns: [0],
        indicators: false,
      },
      fixedColumnsLeft: window.innerWidth < 768 ? 0 : 2,
      width: "100%",
      height: "576px",
      rowHeaders: true,
      columnSorting: true,
      manualRowResize: true,
      manualColumnResize: true,
      manualRowMove: true,
      manualColumnMove: true,
      contextMenu: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const clearFilters = () => {
      filterValues.category = "";
      filterValues.status = "";
      filterValues.date = "";
      filterValues.search = "";
      fetchVodList();
    };
    return {
      isLoading,
      isVideoModalOpen,
      videoName,
      hotRef,
      hotSettings,
      vodList,
      filterValues,
      clearFilters,
      fetchVodList,
    };
  },
};
</script>
