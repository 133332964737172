import { createRouter, createWebHistory } from "vue-router";
import { authInstance } from "./lib/adminInstance";
import HomePage from "./pages/HomePage.vue";
import LoginPage from "./pages/admin/LoginPage.vue";
import AdminTvArchive from "./pages/admin/AdminTvArchive.vue";
import NotFoundPage from "./pages/NotFoundPage.vue";
import AdminDashboard from "./pages/admin/AdminDashboard.vue";
import AdminVideoUpload from "./pages/admin/AdminVideoUpload.vue";
import AdminVodList from "./pages/admin/AdminVodList.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: HomePage,
      meta: { title: "Home Page" },
    },
    {
      path: "/login",
      name: "LoginPage",
      component: LoginPage,
      meta: { title: "Login Page" },
    },
    {
      path: "/admin/tv-archive",
      name: "TvArchive",
      component: AdminTvArchive,
      meta: { title: "TВ архив", requiresAuth: true },
    },
    {
      path: "/admin/dashboard",
      name: "AdminDashboard",
      component: AdminDashboard,
      meta: { title: "Хянах самбар", requiresAuth: true },
    },
    {
      path: "/admin/video/upload",
      name: "AdminVideoUpload",
      component: AdminVideoUpload,
      meta: { title: "Бичлэг оруулах", requiresAuth: true },
    },
    {
      path: "/admin/vod-list",
      name: "AdminVodList",
      component: AdminVodList,
      meta: { title: "Бичлэгийн жагсаалт", requiresAuth: true },
    },

    { path: "/:pathMatch(.*)*", name: "Page404", component: NotFoundPage, meta: { title: "Page Not Found" } },
  ],
});

const checkAuth = async () => {
  try {
    const response = await authInstance.get("/check");
    return response.data.authorized;
  } catch (error) {
    console.error("Error in checkAuth:", error);
    return false;
  }
};

router.beforeEach(async (to, from, next) => {
  const isAuth = await checkAuth();

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuth) {
      next();
    } else {
      next("/login");
    }
  } else if (to.path === "/login" && isAuth) {
    next("/admin/dashboard");
  } else {
    next();
  }
});

export default router;
