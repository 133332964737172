<template>
  <admin-container>
    <loading-animation v-if="isLoading" />
    <div class="text-red-500 uppercase mb-4">Testing VOD</div>

    <!-- IS SERIES -->
    <div class="flex items-center gap-4 mb-4">
      <div class="flex items-center">
        <input
          :checked="!isSeries"
          @change="isSeries = false"
          id="checked-checkbox"
          type="checkbox"
          value=""
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
        />
        <label for="checked-checkbox" class="ms-2 text-sm font-medium text-gray-900">Цуврал биш</label>
      </div>
      <div class="flex items-center">
        <input
          :checked="isSeries"
          @change="isSeries = true"
          id="default-checkbox"
          type="checkbox"
          value=""
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
        />
        <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900">Цуврал</label>
      </div>
    </div>
    <!-- SINGLE VIDEO -->
    <non-series-upload-form v-if="!isSeries" />
    <!-- SERIES VIDEO -->
    <series-upload-form v-else />

    <button @click="uploadVideo" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">{{ isLoading ? "Uploading..." : "Upload" }}</button>
  </admin-container>
</template>
<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import AdminContainer from "./AdminContainer.vue";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";
import NonSeriesUploadForm from "@/components/upload/NonSeriesUploadForm.vue";
import SeriesUploadForm from "@/components/upload/SeriesUploadForm.vue";

export default {
  components: {
    AdminContainer,
    LoadingAnimation,
    NonSeriesUploadForm,
    SeriesUploadForm,
  },
  name: "AdminVideoUpload",
  setup() {
    const store = useStore();

    const isSeries = ref(false);
    const categories = computed(() => store.state.admin.categories);
    const videoData = computed(() => store.state.admin.videoData);
    const seriesData = computed(() => store.state.admin.seriesData);
    const isLoading = computed(() => store.state.admin.isLoading);

    const fileInput = ref(null);

    onMounted(() => {
      store.dispatch("admin/fetchCategories");
    });

    // const validateFile = (file) => {
    //   const allowedTypes = ["video/mp4", "video/mkv", "video/avi"];
    //   if (!file) {
    //     alert("Бичлэг сонгоно уу");
    //     return false;
    //   }
    //   if (!allowedTypes.includes(file.type)) {
    //     alert("Бичлэгийн файлын төрөл буруу байна");
    //     return false;
    //   }
    //   return true;
    // };

    const uploadVideo = async () => {
      try {
        // if (!videoData.value.videoName) {
        //   alert("Бичлэгийн нэрийг оруулна уу");
        //   return;
        // }
        // if (!videoData.value.videoCategory) {
        //   alert("Бичлэгийн төрөл сонгоно уу");
        //   return;
        // }
        // if (!videoData.value.videoDescription) {
        //   alert("Бичлэг сонгоно уу");
        //   return;
        // }
        // if (!validateFile(videoData.value.video)) {
        //   return;
        // }

        if (!isSeries.value) {
          console.log("uploading non series video");

          await store.dispatch("admin/uploadNonSeriesVideo");
        } else {
          console.log("uploading series video");

          await store.dispatch("admin/uploadSeriesVideo");
        }
      } catch (error) {
        console.log(error);
      }
    };

    return {
      isSeries,
      categories,
      isLoading,
      videoData,
      fileInput,
      seriesData,
      uploadVideo,
    };
  },
};
</script>
