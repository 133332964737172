<template>
  <admin-container>
    <div class="flex gap-4 items-center mb-4">
      <button @click="toggleIsSeries(false)" class="bg-blue-500 text-white px-4 py-2 rounded-lg" :class="{ 'bg-blue-500': !isSeries, 'bg-gray-500': isSeries }">Цуврал биш</button>
      <button @click="toggleIsSeries(true)" class="bg-blue-500 text-white px-4 py-2 rounded-lg" :class="{ 'bg-blue-500': isSeries, 'bg-gray-500': !isSeries }">Цуврал</button>
    </div>
    <non-series-list v-if="!isSeries" />
    <series-list v-else />
  </admin-container>
</template>

<script>
import { ref } from "vue";

import AdminContainer from "@/pages/admin/AdminContainer.vue";
import NonSeriesList from "@/pages/admin/NonSeriesList.vue";
import SeriesList from "@/pages/admin/SeriesList.vue";
export default {
  name: "AdminVodList",
  components: {
    AdminContainer,
    NonSeriesList,
    SeriesList,
  },
  setup() {
    const isSeries = ref(false);

    const toggleIsSeries = (value) => {
      isSeries.value = value;
    };
    return {
      isSeries,
      toggleIsSeries,
    };
  },
};
</script>
