<template>
  <div class="max-w-md flex flex-col gap-4 mb-4">
    <div class="col-span-1">
      <label for="videoName" class="block mb-2 text-sm font-medium text-gray-900">Бичлэгийн нэр</label>
      <input
        type="text"
        id="videoName"
        name="videoName"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн нэр"
        required
        :value="videoData.videoName"
        @change="handleNonSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="videoCategory" class="block mb-2 text-sm font-medium text-gray-900">Бичлэгийн төрөл</label>
      <select
        id="videoCategory"
        name="videoCategory"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн нэр"
        required
        :value="videoData.videoCategory"
        @change="handleNonSeriesInputChanges"
      >
        <option disabled selected value>-- Сонгох --</option>
        <option v-for="category in categories" :key="category.category_id" :value="category.category_id">{{ category.name }}</option>
      </select>
    </div>
    <div class="col-span-1">
      <label for="videoThumbnail" class="block mb-2 text-sm font-medium text-gray-900">Бичлэгийн thumbnail зураг</label>
      <input
        type="file"
        name="videoThumbnail"
        ref="thumbnailInputRef"
        id="videoThumbnail"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн үнэ"
        required
        @change="handleNonSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="videoName" class="block mb-2 text-sm font-medium text-gray-900"> Бичлэгийн файл </label>
      <input
        type="file"
        id="video"
        name="video"
        ref="fileInputRef"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн файл"
        required
        @change="handleNonSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="videoPrice" class="block mb-2 text-sm font-medium text-gray-900">Бичлэгийн үнэ</label>
      <input
        type="number"
        id="videoPrice"
        name="videoPrice"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн үнэ"
        required
        :value="videoData.videoPrice"
        @change="handleNonSeriesInputChanges"
      />
    </div>
    <div class="col-span-1">
      <label for="videoDescription" class="block mb-2 text-sm font-medium text-gray-900">Бичлэгийн тайлбар</label>
      <textarea
        name="videoDescription"
        id="videoDescription"
        cols="30"
        rows="10"
        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        placeholder="Бичлэгийн тайлбар"
        required
        :value="videoData.videoDescription"
        @change="handleNonSeriesInputChanges"
      ></textarea>
    </div>
  </div>
</template>

<script>
import { computed, watch, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "NonSeriesUploadForm",

  // eslint-disable-next-line no-unused-vars
  setup(props, { emit }) {
    const store = useStore();
    const videoData = computed(() => store.state.admin.videoData);
    const categories = computed(() => store.state.admin.categories);
    const fileInputRef = ref(null);
    const thumbnailInputRef = ref(null);

    const handleNonSeriesInputChanges = (event) => {
      if (event.target.name === "videoThumbnail" || event.target.name === "video") {
        store.commit("admin/setVideoData", { key: event.target.name, value: event.target.files[0] });
        return;
      }
      store.commit("admin/setVideoData", { key: event.target.name, value: event.target.value });
    };

    watch([() => videoData.value.videoThumbnail, () => videoData.value.video], (newValues, oldValues) => {
      const [newThumbnail, newVideo] = newValues;
      const [oldThumbnail, oldVideo] = oldValues;

      if (!newThumbnail && oldThumbnail) {
        thumbnailInputRef.value.value = "";
      }
      if (!newVideo && oldVideo) {
        fileInputRef.value.value = "";
      }
    });

    return { videoData, categories, handleNonSeriesInputChanges, fileInputRef, thumbnailInputRef };
  },
};
</script>
