<template>
  <div id="popup-modal" class="overflow-y-auto overflow-x-hidden fixed inset-0 z-[10001] flex justify-center items-center">
    <div class="relative p-4 w-full max-w-md">
      <div class="relative bg-white rounded-lg shadow">
        <button
          type="button"
          class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
          @click="closeModal"
        >
          <svg class="w-3 h-3" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <path d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7L1 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          </svg>
          <span class="sr-only">Close modal</span>
        </button>
        <div class="p-4 text-center">
          <svg class="mx-auto mb-4 w-12 h-12 text-gray-400" fill="none" viewBox="0 0 20 20" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          </svg>
          <h3 class="mb-5 text-lg font-normal text-gray-500">{{ value }} устгахдаа итгэлтэй байна уу?</h3>
          <button
            type="button"
            class="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
            @click="deleteItem"
          >
            Устгах
          </button>
          <button
            type="button"
            class="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:ring-gray-100"
            @click="closeModal"
          >
            Болих
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRef } from "vue";
export default {
  name: "DeleteModal",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const value = toRef(props, "title");

    const closeModal = () => {
      emit("close");
    };

    const deleteItem = () => {
      emit("delete");
    };

    return {
      value,
      closeModal,
      deleteItem,
    };
  },
};
</script>
