<template>
  <div class="linear-activity">
    <div class="indeterminate"></div>
  </div>
</template>

<style>
.linear-activity {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  background-color: #ddd;
  overflow: hidden;
}

.indeterminate {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  background-color: #2196f3;
  -webkit-animation: indeterminate 1.8s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 1.8s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@media (max-width: 600px) {
  .linear-activity {
    top: 40px;
  }
}
</style>
